<template>
  <div :class="`m-3 wrapper  wrapper--default`">
    <div class="text-center p-2">
      <h2>Verwacht deze week</h2>
      <span @click="previousWeek"><i class="fas fa-arrow-left"></i></span>
      <span class="text-center m-2"
        >Week: {{ week_number }}, {{ year_number }}</span
      >
      <span @click="nextWeek"><i class="fas fa-arrow-right"></i></span>
    </div>
    <Loading v-if="loading" />
    <div class="d-flex flex-row" v-if="!loading">
      <div v-if="expected_trucks" class="m-3 flex-grow-1 table-responsive">
        <h4 class="text-center"><i class="fas fa-truck"></i> Trucks</h4>
        <ExpectedThisWeekTable
          :table_data="expected_trucks"
          table_style="200"
        />
      </div>
      <div v-if="expected_trailers" class="m-3 flex-grow-1 table-responsive">
        <h4 class="text-center"><i class="fas fa-trailer"></i> Trailers</h4>
        <ExpectedThisWeekTable
          :table_data="expected_trailers"
          table_style="200-trailer"
        />
      </div>
      <div v-if="expected_vans" class="m-3 flex-grow-1 table-responsive">
        <h4 class="text-center"><i class="fas fa-shuttle-van"></i> Vans</h4>
        <ExpectedThisWeekTable
          :table_data="expected_vans"
          table_style="210"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import moment from "moment";
import ExpectedThisWeekTable from "@/components/ExpectedThisWeekTable.vue";
import {
  addTime,
  subtractTime,
  getWeekNumber,
  getYearNumber,
} from "@/functions/date.js";
export default {
  components: { Loading, ExpectedThisWeekTable },
  data: () => ({
    loading: true,
    full_date: moment(),
    week_start: null,
    week_end: null,
    expected_trucks: null,
    expected_vans: null,
    expected_trailers: null,
    week_number: null,
    year_number: null,
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      this.week_start = this.full_date.startOf("week").format("YYYY-MM-DD");
      this.week_end = this.full_date.endOf("week").format("YYYY-MM-DD");
      this.week_number = getWeekNumber(this.full_date);
      this.year_number = getYearNumber(this.full_date);
      request(
        `open-purchase-orders-by-week/${this.week_start}/${this.week_end}`,
        "GET"
      ).then(({ expected_trucks, expected_vans, expected_trailers }) => {
        this.expected_trucks =
          Object.keys(expected_trucks).length != 0 && expected_trucks;
        this.expected_vans =
          Object.keys(expected_vans).length != 0 && expected_vans;
        this.expected_trailers =
          Object.keys(expected_trailers).length != 0 && expected_trailers;
        this.loading = false;
      });
    },
    previousWeek() {
      this.full_date = subtractTime(7, this.full_date, "days");
      this.getData();
    },
    nextWeek() {
      this.full_date = addTime(7, this.full_date, "days");
      this.getData();
    },
  },
};
</script>
