<template>
  <table :class="`table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <tr>
        <th class="table__head--text">Srt</th>
        <th class="table__head--text">Status</th>
        <th class="table__head--text">Item</th>
        <th class="table__head--text">Voertuig</th>
        <th class="table__head--text" style="width:105px;">Verwacht</th>
        <th class="table__head--text">Bet.</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in table_data"
        :key="key"
        :class="`table__row--color`"
      >
        <td class="table__cell--default">
          <div v-if="item.soortnaam == 'halen'" class="text-center">
            <span
              v-b-tooltip.hover
              :title="`Halen: ${item.transporteur}`"
              ><i class="fas fa-arrow-left"></i
            ></span>
          </div>
          <div class="text-center" v-else>
            <span v-b-tooltip.hover title="Wordt gebracht"
              ><i class="fas fa-arrow-right"></i
            ></span>
          </div>
        </td>
        <td class="table__cell--default">
          <div v-if="item.statusnaam == 'nieuw'" class="text-center">
            <span
              class="dot dot--red "
              v-b-tooltip.hover
              title="Nieuw"
            ></span>
          </div>
          <div class="text-center" v-else>
            <span
              class="dot dot--blue"
              v-b-tooltip.hover
              title="Ingepland"
            ></span>
          </div>
        </td>
        <td class="table__cell--default">
          <router-link
            :to="{ name: 'ItemPage', params: { id: item.itemnummer, bu: item.bu } }"
            >{{ item.itemnummer }}</router-link
          >
        </td>
        <td class="table__cell--default">{{ item.voertuig }}</td>
        <td class="table__cell--default">{{ item.aanleverdatum }}</td>
        <td class="table__cell--default">
          <div v-if="item.betaald == 1">
            <span v-b-tooltip.hover :title="item.betaaldatum"
              ><i class="fas fa-euro-sign"></i
            ></span>
          </div>
          <div v-else>
            <span v-b-tooltip.hover title="Nog niet betaald"
              ><i class="fab fa-creative-commons-nc-eu"></i
            ></span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    table_data: Array,
    table_style: String,
  },
};
</script>
